<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items="inspectionsData"
                :fields="fields"
                responsive="sm"
                :busy="isBusy"
                :per-page="perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                tbody-tr-class="rowClass"
                :filter-included-fields="filterOn"
                @row-clicked="inspectionSelected"
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                </template>
               
                  <template #cell(createdAt)="row">
            {{ moment(row.item.createdAt).format("lll") }}
          </template>
                <template #cell(phone_number)="row">
                  +{{ row.item.user.country_code }}-{{ row.item.user.phone_number }}
                </template>
                <template #cell(driver_email)="row">
                  {{ row.item.user.email }} 
                </template>
                <template #cell(driver_id)="row">
                  {{ row.item.user.driver_id }} 
                </template>
                <template #cell(vehicle_number)="row">
                  {{ row.item.vehicle.plate_number }} 
                </template>
                
                
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      @change="updatePage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import { inspections } from "@/config/api/inspections";

export default {
  components: { Layout },
  data() {
    return {
      inspectionsData: [],
      isBusy: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        { key: "createdAt", sortable: true },
        { key: "driver_email", sortable: true },
        { key: "driver_id", sortable: false },
        { key: "phone_number", sortable: false },
        { key: "vehicle_number", sortable: false },
        { key: "starting_milage", sortable: true },
        { key: "end_milage", sortable: true },
      ],
    };
  },
  computed: {},
  mounted() {},
  created() {
    this.getAllUser();
  },
    watch: {
    filter: function(newVal) {
      if (newVal.length > 2 || newVal.length === 0) {
        setTimeout(() => {
          this.getAllUser(1, this.filter);
        }, 2000)
      }
    }
  },
  methods: {
    updatePage(page)
    {
      this.getAllUser(page);
    },
    getAllUser(page = 1, search = "") {
      this.isBusy = true;
      const api = inspections.get;
      api.params = {page: page, search: search}
      this.generateAPI(api)
        .then((res) => {
          console.log(res);
          this.inspectionsData = res.data.inspections.totalData;
          this.totalRows = res.data.inspections.totalCount.count;
          this.isBusy = false;
        })
        .catch((err) => {
          console.error(err.response.data);
        });
    },
    
    inspectionSelected(data) {
      this.$router.push({ name: "InspectionDetails", params: { id: data._id } });
      console.log(data);
    },
  },
};
</script>

<style>
.rowClass {
  cursor: pointer;
}
.rowClass:hover {
  background: #f2f2f2;
  /* color: #ffffff; */
}
</style>